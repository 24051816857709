<script>
import apiTool from '@/command/apiTool'

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onClick(event, item) {
      event.stopPropagation()
      event.preventDefault()
      item.onClick && item.onClick()
    },
  },
  render() {
    //** 表格按钮权限 */
    let buttonName = apiTool.getButtonName()
    let filterButton = []
    if (process.env.NODE_ENV === 'development') {
      filterButton = this.data
    } else {
      filterButton = this.data.filter((el) => buttonName.some((ea) => el.name.indexOf(ea) !== -1))
    }

    return (
      <div class="table-button">
        {(filterButton || [])
          .filter((e) => e.display)
          .map((e) => {
            if (e.type == 'pop') {
              return (
                <a-popconfirm
                  title={e.popTitle || '确定要删除此条数据吗?'}
                  ok-text="确定"
                  cancel-text="取消"
                  onConfirm={(event) => {
                    e.onClick && this.onClick(event, e)
                  }}
                  onCancel={() => {}}
                >
                  <div style={{ color: e.color }}>{e.name}</div>
                </a-popconfirm>
              )
            } else {
              return (
                <div style={{ color: e.color }} onClick={(event) => this.onClick(event, e)}>
                  {e.name}
                </div>
              )
            }
          })}
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.table-button {
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    color: rgb(55, 93, 246);
    cursor: pointer;
  }
  & > div:not(:last-child) {
    margin-right: 10px;
  }
}
</style>
