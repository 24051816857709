<script>
import { Timeline } from 'ant-design-vue'
import moment from 'moment'
export default {
  props: ['formData'],
  data() {
    return {
      timelineData: [],
    }
  },
  mounted() {
    this.timelineData = this.formData
  },
  render() {
    return (
      <Timeline>
        {this.timelineData.map((e) => {
          return (
            <Timeline.Item>
              {e.text}
              <br />
              {moment(e.createTime).format('YYYY/MM/DD HH:mm')}
            </Timeline.Item>
          )
        })}
      </Timeline>
    )
  },
}
</script>
