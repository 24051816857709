<script>
import TemplateHeaderTable from '@/components/TemplateHeaderTable'
import { delAction, downloadAction, getAction, getParams } from '../../command/netTool'
import TableButton from '@/components/TableButton'
import { handleDeep3 } from '@/command/dataProcess'
import apiTool from '@/command/apiTool'
import TimeLineView from '@/components/TimeLineView'
import moment from 'moment'
import { message } from 'ant-design-vue'

export default {
  name: 'basicInformation',
  data() {
    return {
      levelArr: [], //五级联动
    }
  },
  computed: {
    categoryArr() {
      return this.$store.state.categoryArr
    },
    scjyArr() {
      return this.$store.state.scjyArr
    },
    sctypeArr() {
      return this.$store.state.sctypeArr
    },
  },
  mounted() {
    getAction('/api/dataoperation/region/freeTree?level=4&id=330000000000').then((res) => {
      if (res && res.length > 0) {
        this.levelArr = handleDeep3(res.filter((item) => item.id == '330200000000'))
        this.$forceUpdate()
      }
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '名称',
          type: 'input',
          key: 'mainName',
          labelCol: { span: 8 },
          wrapperCol: { span: 16 },
        },
        {
          name: '法人名称',
          type: 'input',
          key: 'personName',
        },
        {
          name: '所属街道',
          type: 'cascader',
          key: 'streetCode',
          typeData: this.levelArr,
          changeOnSelect: true,
        },
        {
          name: '主体类型',
          type: 'treeSelect',
          key: 'mainBodyType',
          isCheckable: true,
          typeData: this.categoryArr,
        },
        {
          name: '产业类型',
          key: 'industryType',
          type: 'selectMode',
          labelCol: { span: 8, xl: 10 },
          wrapperCol: { span: 16, xl: 14 },
          typeData: this.sctypeArr,
          props: {
            mode: 'multiple',
          },
        },
        {
          name: '生产经营者类型',
          key: 'producerType',
          type: 'select',
          typeData: this.scjyArr,
          labelCol: { span: 8, xl: 10 },
          wrapperCol: { span: 16, xl: 14 },
        },
        {
          name: '冷库情况',
          key: 'lkInfo',
          type: 'select',
          typeData: [
            {
              name: '有冷库',
              value: '1',
            },
            {
              name: '有需求',
              value: '2',
            },
            {
              name: '无需求',
              value: '3',
            },
          ],
        },
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          onClick: () => {
            this.$router.push('/basicInformation/detail')
          },
        },
        {
          name: '导出excel',
          onClick: (data) => {
            let dataT = getParams(data)
            downloadAction(`/api/dataoperation/collectionMainBody/mainBodyExport${dataT}`, '主体列表文件.xlsx')
          },
        },
      ]
    },
    getColumns() {
      return [
        {
          title: '主体名称',
          dataIndex: 'mainName',
          align: 'left',
        },
        {
          title: '法人及手机号',
          dataIndex: 'personName',
          align: 'left',
          render: ({ records }) => {
            return `${records.personName ?? ''} ${records.linkPhone ? '（' + records.linkPhone + '）' : ''}`
          },
        },
        {
          title: '主体类型',
          dataIndex: 'mainBodyTypeName',
          align: 'left',
          width: 150,
        },
        {
          title: '产业类型',
          dataIndex: 'industryTypeName',
          align: 'left',
        },
        {
          title: '生产经营者类型',
          dataIndex: 'producerTypeName',
          align: 'left',
          sorter: true,
          width: 150,
        },
        {
          title: '所属街道',
          dataIndex: 'streetName',
          align: 'left',
          sorter: true,
          width: 150,
        },
        {
          title: '土地总面积(亩)',
          dataIndex: 'areaLand',
          align: 'left',
          sorter: true,
          width: 150,
        },
        {
          title: '数据来源',
          dataIndex: 'dataSource',
          align: 'left',
          //   filters: [
          //     {
          //       text: '监管导入',
          //       value: '监管导入',
          //     },
          //     {
          //       text: '新增',
          //       value: '新增',
          //     },
          //   ],
          render: ({ text }) => ['监管导入', '新增'][text],
        },
        {
          title: '最新操作',
          dataIndex: 'updateName',
          align: 'left',
          render: ({ text, records }) =>
            `${text ?? ''} ${records.updateTime ? '（' + moment(records.updateTime).format('YYYY/MM/DD') + '）' : ''}`,
        },
        {
          title: '操作',
          dataIndex: 'a1',
          render: ({ records }) => {
            const data = [
              {
                name: '操作记录',
                display: true,
                onClick: () => {
                  getAction('/api/dataoperation/collectionMainBody/historyPage?mainId=' + records.id).then((res) => {
                    if (res && res.length > 0) {
                      let timelineData = res.map((e) => {
                        return {
                          ...e,
                          text:
                            e.createName +
                            ' ' +
                            (e.operationType == '0' ? '添加了' : '更新了') +
                            ' ' +
                            (e.mainBody.mainName != null ? e.mainBody.mainName : ''),
                        }
                      })

                      apiTool.showDrawer({
                        title: '操作记录',
                        width: 600,
                        view: TimeLineView,
                        formData: timelineData,
                      })
                    } else {
                      this.$message.info('暂无操作记录！')
                    }
                  })
                },
              },
              {
                name: '冷库',
                display: true,
                onClick: () => {
                  localStorage.removeItem('lkList')
                  this.$router.push({
                    name: 'detailCold',
                    query: {
                      mainId: records.id,
                      creditCode: records.creditCode,
                    },
                  })
                },
              },
              {
                name: '编辑',
                display: true,
                onClick: () => {
                  this.$router.push({
                    path: '/basicInformation/detail',
                    query: {
                      id: records.id,
                    },
                  })
                },
              },
              {
                name: '删除',
                type: 'pop',
                color: '#ff2659',
                display: true,
                onClick: () => {
                  delAction(`/api/dataoperation/collectionMainBody/delete/${records.id}`).then((r) => {
                    message.success('删除成功！')
                    this.$refs.table.getList()
                  })
                },
              },
            ]
            return <TableButton data={data} />
          },
        },
      ]
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        url={'/api/dataoperation/collectionMainBody/page'}
        tableColumns={this.getColumns()}
        headerData={this.getHeader()}
        button={this.getButton()}
        ref={'table'}
      />
    )
  },
}
</script>
